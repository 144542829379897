.about-content {
  padding: 30px 40px;
  max-width: 450px;
  font-style: normal; }
  @media screen and (max-width: 1024px) {
    .about-content {
      padding-bottom: 0;
      padding: 20px; } }

.about-content p {
  margin: 1em 0; }

.about-content p:first-child {
  margin-top: 0; }

.about-content p:last-child {
  margin-bottom: 0; }

.about-content a {
  display: inline-block;
  border-bottom: 1px solid black;
  line-height: 25px; }
  @media screen and (max-width: 600px) {
    .about-content a {
      display: inline; } }

.mailing-list-link {
  cursor: pointer; }

.mailing-list-form {
  display: none; }

.mailing-list-form.submitting {
  opacity: 0.5; }

.mailing-list-form input {
  border: 1px solid black;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  padding: 10px 20px;
  padding-top: 15px; }
  .mailing-list-form input:focus {
    outline: none; }

.mailing-list-form input[type="email"] {
  max-width: 100%;
  box-sizing: border-box; }

.mailing-list-form input[type="submit"] {
  margin-top: 7px;
  cursor: pointer; }

.subscribe-error {
  display: none;
  margin-top: 0.5em; }

.subscribe-success {
  display: none; }
