@font-face {
  font-family: "BaskervilleMTPro";
  src: url(src/fonts/Baskerville.eot);
  src: url(src/fonts/Baskerville.eot?#iefix) format("embedded-opentype"), url(src/fonts/Baskerville.woff2) format("woff2"), url(src/fonts/Baskerville.woff) format("woff"), url(src/fonts/Baskerville.ttf) format("truetype"); }

@font-face {
  font-family: "BaskervilleMTPro";
  font-style: italic;
  src: url(src/fonts/BaskervilleItalic.eot);
  src: url(src/fonts/BaskervilleItalic.eot?#iefix) format("embedded-opentype"), url(src/fonts/BaskervilleItalic.woff2) format("woff2"), url(src/fonts/BaskervilleItalic.woff) format("woff"), url(src/fonts/BaskervilleItalic.ttf) format("truetype"); }
