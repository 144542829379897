html {
  height: 100%; }

body {
  margin: 0;
  font-family: "BaskervilleMTPro", "Times New Roman", serif;
  font-style: italic;
  font-size: 32px;
  overflow-x: hidden;
  background-color: #efefef; }

a {
  color: inherit;
  text-decoration: none; }

.content {
  min-height: 100vh; }
  @media screen and (max-width: 600px) {
    .content {
      padding-bottom: 30px; } }

.soft-logo,
.meta-links {
  display: block;
  position: absolute;
  bottom: 30px; }
  @media screen and (max-width: 600px) {
    .soft-logo,
    .meta-links {
      text-align: center;
      position: static; } }

@media screen and (max-width: 600px) {
  .soft-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .main-page .soft-logo-container {
      height: 68vh; } }

.soft-logo {
  left: 40px; }
  @media screen and (max-width: 600px) {
    .soft-logo {
      padding: 50px 0;
      padding-bottom: 60px; }
      .about-page .soft-logo {
        display: none; } }

.meta-links {
  right: 40px;
  font-style: normal;
  text-align: right; }
  .meta-links a {
    display: block; }
  @media screen and (max-width: 600px) {
    .meta-links {
      margin-right: 20px;
      margin-top: 80px; } }

/* Tagline */
.tagline {
  display: none;
  position: absolute;
  width: 240px;
  top: 30px;
  right: 40px;
  font-style: normal;
  text-align: center; }
  @media screen and (max-width: 1024px) {
    .tagline {
      display: none; } }

.tagline-active-pre {
  display: block;
  opacity: 0;
  transition: transform 0.2s ease-in, opacity 0.2s;
  transform: scale(0) rotate(-180deg); }

.tagline-active {
  opacity: 1;
  transform: scale(1) rotate(0deg); }

/* Icons */
.icons-container {
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  padding: 40px; }
  @media screen and (max-width: 600px) {
    .icons-container {
      display: block;
      padding: 0 20px;
      padding-bottom: 100px; } }

.app-stub {
  display: block;
  margin-right: 45px;
  opacity: 0;
  width: 300px;
  margin-bottom: 60px;
  cursor: pointer; }
  .ready .app-stub {
    opacity: 1; }
  .app-stub:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 600px) {
    .app-stub {
      margin-left: auto;
      margin-right: auto;
      width: 250px; } }

.app-stub-icon {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.125);
  border-radius: 17.3%;
  overflow: hidden;
  width: 100%;
  height: 300px;
  background-color: #ddd;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; }
  @media screen and (min-width: 1024px) {
    .app-stub-icon:hover {
      transform: scale(1.1); } }
  @media screen and (max-width: 600px) {
    .app-stub-icon {
      height: 250px; } }

.app-stub-info {
  display: none;
  margin-top: 20px;
  text-align: center;
  line-height: 1.2; }
  @media screen and (max-width: 1024px) {
    .app-stub-info {
      display: block; } }

.app-stub-tagline {
  font-style: italic; }

.icon-tagline {
  display: none;
  flex: 1; }

/* Flight Simulator icon */
.fs-app-stub .app-stub-icon {
  background: url(public/fs-icon.png);
  background-size: 80% 80%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: white; }

/* ColorChat icon */
.colorchat-app-stub .app-stub-icon {
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #000; }

.colorchat-icon-letter {
  font-family: sans-serif;
  width: 33.33333%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #00f; }

/* Unsupported styles */
body.unsupported {
  background: #f2f2f2; }
