.privacy-policy .container {
  max-width: 800px;
  margin: 0 auto;
  font-style: normal;
  font-size: 24px;
  padding-top: 40px;
  padding-bottom: 200px;
  color: #666;
  line-height: 1.4; }
  @media screen and (max-width: 1024px) {
    .privacy-policy .container {
      padding-top: 20px;
      padding-bottom: 100px;
      margin: 0 24px; } }
  @media screen and (max-width: 600px) {
    .privacy-policy .container {
      padding-top: 0;
      margin: 0 12px;
      font-size: 20px; } }

.privacy-policy .container h1,
.privacy-policy .container h2,
.privacy-policy .container b {
  font-size: inherit;
  color: #000;
  font-weight: normal; }

.privacy-policy .container h1 {
  font-size: 36px;
  line-height: 1.2; }

.privacy-policy .container ul {
  margin-top: 0; }
  @media screen and (max-width: 600px) {
    .privacy-policy .container ul {
      padding: 0 30px; } }

.privacy-policy .container a {
  color: #00f;
  text-decoration: none; }
